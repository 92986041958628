import React from "react";
import "../style/footer.css";

export default class Footer extends React.Component {
  render() {
    return (
      <div id="Footer">
        <div className="color-box" />
        <div className="page-footer"></div>
      </div>
    );
  }
}
